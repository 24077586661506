<template>
  <div class="goods">
    <div class="goods_seq">
      <span
        >상품 번호 {{ item.goods_seq }}{{ item.item_seq
        }}{{ item.item_option_seq }}</span
      >
      <span class="right"> 주문 개수 {{ item.ea ? item.ea : 0 }}개 </span>
    </div>
    <div class="goods_box">
      <div class="img">
        <img :src="item.image" @error="replaceImg" :alt="item.goods_name" />
      </div>
      <div class="txt">
        <h5>{{ item.goods_name }}</h5>
        <!-- <h6>
          {{
            won(parseInt(item.sale_price) * parseInt(item.ea ? item.ea : 0))
          }}원
          <span
            v-if="
              parseInt(item.sale_price) * parseInt(item.ea ? item.ea : 0) <
              parseInt(item.out_price) * parseInt(item.ea ? item.ea : 0)
            "
          >
            {{
              won(parseInt(item.out_price) * parseInt(item.ea ? item.ea : 0))
            }}원
          </span>
        </h6> -->
        <h6>
          {{ won(parseInt(item.sale_price)) }}원
          <span v-if="parseInt(item.sale_price) < parseInt(item.out_price)">
            {{ won(parseInt(item.out_price)) }}원
          </span>
        </h6>
        <p class="shipping_txt">(배송비 제외)</p>
        <p v-if="item.title1">{{ item.title1 }} : {{ item.option1 }}</p>
        <p v-if="item.title2">{{ item.title2 }} : {{ item.option2 }}</p>
        <p v-if="item.title3">{{ item.title3 }} : {{ item.option3 }}</p>
        <p v-if="item.title4">{{ item.title4 }} : {{ item.option4 }}</p>
        <p v-if="item.title5">{{ item.title5 }} : {{ item.option5 }}</p>
        <p v-for="(sub, index) in item.suboptions" :key="index">
          추가 : {{ sub.title }}({{ sub.suboption }})
          <!-- {{ won(parseInt(sub.price) * parseInt(sub.ea ? sub.ea : 0)) }}원 -->
          {{ won(parseInt(sub.price)) }}원 {{ sub.ea ? sub.ea : 0 }}개
        </p>
        <p v-if="parseInt(item.provider_seq) === 1">
          <span>{{ item.provider_name }}</span>
        </p>
        <p v-else>
          <span>{{ item.provider_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    won(str) {
      return this.$won(str);
    },
  },
};
</script>
<style lang="scss" scoped>
.goods_seq {
  margin: 10px 0;
  .right {
    float: right;
  }
}
.rn_my_box_24 .sect_cont {
  .box {
    div {
      border-bottom: none;
    }
    .goods {
      padding-bottom: 15px;
      border-bottom: 1px solid #cccccc;
      div {
        padding: 0;
      }
      .goods_box {
        display: flex;
        .img {
          margin-right: 7px;
          width: 110px;
          img {
            width: 110px;
            height: 110px;
          }
        }
        .txt {
          width: calc(100% - 117px);
        }
        .shipping_txt {
          font-size: 11px;
          margin-bottom: 10px;
          line-height: 11px;
        }
        h5 {
          font-size: 14px;
          color: #545454;
          line-height: 18px;
          max-height: 36px;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 10px;
        }
        h6 {
          font-size: 16px;
          color: black;
          margin-bottom: 5px;
          span {
            font-size: 12px;
            text-decoration: line-through;
            color: #909090;
            margin-left: 12px;
          }
        }
        p {
          width: 100%;
          overflow: hidden;
          color: #6d6d6d;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
