<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      신고하기<router-link to="" @click.native="closeModal()"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_25">
      <div class="top">
        <ul>
          <li>
            상품이 발송되지 않았음에도 불구하고 '배송중'으로 표시된 경우
            [허위발송]을 누른 후 구체적인 사유를 적어 신고해 주세요.
          </li>
          <li>
            상품이 도착하지 않았음에도 불구하고 '배송완료'로 표시된 경우 [상품
            미도착]을 누른 후 구체적인 사유를 적어 신고해 주세요.
          </li>
          <li>
            그 외의 사유에 대해서는 [기타] 버튼을 누른 후 구체적인 사유를 적어
            신고해 주세요.
          </li>
        </ul>
      </div>
      <OrderItem
        :orderData="orderData"
        :orderSeq="orderSeq"
        :itemSeq="itemSeq"
        :optionSeq="optionSeq"
        :isDeclaration="true"
        ref="orderItem"
        @removeItemAdd="removeItemAdd"
        :style="{ borderBottom: 'none', marginBottom: 0 }"
      />
      <div class="section_input">
        <div class="radio_box">
          <label
            ><input
              type="radio"
              name="reason"
              v-model="reason"
              value="허위발송"
              @change="clearTextarea"
            />허위발송</label
          ><label
            ><input
              type="radio"
              name="reason"
              v-model="reason"
              value="상품 미도착"
              @change="clearTextarea"
            />상품 미도착</label
          ><label
            ><input
              type="radio"
              name="reason"
              v-model="reason"
              @change="clearTextarea"
              value=""
            />기타</label
          >
        </div>
        <div class="text_box">
          <textarea
            name=""
            v-model="detailReason"
            :readonly="reason !== ''"
            maxlength="100"
            placeholder="구체적인 사유를 적어주세요.(100자 미만)"
          ></textarea>
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="setDeclaration">신고하기</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderItem from "@/components/mypage/order/OrderItem";
export default {
  data() {
    return {
      refundItems: [],
      reason: "",
      detailReason: "",
    };
  },
  props: {
    orderSeq: {
      type: String,
      default: "",
    },
    itemSeq: {
      type: String,
      default: "",
    },
    optionSeq: {
      type: String,
      default: "",
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getOrderReturn();
  },
  computed: { ...mapState("order", ["orderData", "result", "msg"]) },
  methods: {
    closeModal(pageInfo = null) {
      this.$emit("closeModal", "declaration", pageInfo);
    },
    clearTextarea() {
      if (this.reason) {
        this.detailReason = "";
      }
    },
    async getOrderReturn() {
      await this.$store.dispatch("order/getOrderReturn", {
        order_seq: this.orderSeq,
      });
      if (!this.isReturnOk) {
        this.$toast.default("신고할 수 있는 상품이 없습니다.");
        this.closeModal();
        return false;
      }
      if (!this.result) {
        this.$toast.default(this.msg);
        this.closeModal();
        return false;
      }
    },
    isReturnOk() {
      let returnCnt = 0;
      Object.keys(this.orderData.loop).forEach((key) => {
        this.orderData.loop[key].export_item.forEach((item, index) => {
          if (
            parseInt(this.itemSeq) === parseInt(item.item_seq) &&
            parseInt(this.optionSeq) === parseInt(item.item_option_seq)
          ) {
            returnCnt += parseInt(item.rt_ea);
          }
        });
      });
      console.log("신고 가능 개수", returnCnt);
      return returnCnt <= 0 ? false : true;
    },
    removeItemAdd(isCheck, data, type = null) {
      if (isCheck) {
        if (type === "all") {
          this.refundItems = data;
        } else {
          this.refundItems.push(data);
        }
      } else {
        if (type === "all") {
          this.refundItems = [];
        } else {
          const idx = this.refundItems.findIndex(
            (item) => item.itemSuboption === data.itemSuboption
          );
          this.refundItems.splice(idx, 1);
        }
      }
      console.log(this.refundItems);
    },
    async setDeclaration() {
      if (this.refundItems.length <= 0) {
        this.$toast.default("신고 할 상품을 선택 해주세요.");
        return false;
      }
      if (!this.reason) {
        if (!this.detailReason) {
          this.$toast.default("구체적인 사유를 적어주세요.");
          return false;
        }
      }
      let formData = new FormData();
      this.refundItems.map((ref) => {
        formData.append("item_seq[]", ref.itemSeq);
        formData.append("item_option_seq[]", ref.itemOption);
        formData.append("item_suboption_seq[]", ref.itemSuboption);
      });
      if (this.reason) {
        formData.append("reason", this.reason);
      } else {
        formData.append("reason", this.detailReason);
      }
      formData.append("order_seq", this.orderSeq);

      await this.$store.dispatch("order/setDeclaration", formData);
      this.$toast.default(this.msg);
      if (this.result) {
        this.closeModal(this.pageInfo);
      }
      // for (let pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }
    },
  },
  components: {
    OrderItem,
  },
};
</script>

<style></style>
