<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      교환요청<router-link to="" @click.native="closeModal()"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_21">
      <div class="top">
        <ul>
          <li>
            교환/반품과 관련된 자세한 사항은 관련 법령과 램 이용약관, 판매자
            고지를 따릅니다.
          </li>
          <li>
            반품이나 교환에 필요한 왕복 배송비와 기타 필요한 비용은 귀책 사유가
            있는 당사자가 부담합니다. 즉, 상품 등의 하자 또는 오배송으로 인한
            반품이나 교환의 경우에는 판매자가, 구매자의 단순 변심으로 인한
            반품이나 교환의 경우에는 구매자가 그 비용을 부담합니다.
          </li>
          <li>
            위 내용을 확인했으면 아래 절차를 따라 내용을 입력하신 후 [반품
            요청하기]를 눌러주세요.
          </li>
        </ul>
      </div>
      <OrderItem
        :orderData="orderData"
        :orderSeq="orderSeq"
        :itemSeq="itemSeq"
        :optionSeq="optionSeq"
        :isReturn="true"
        ref="orderItem"
        @removeItemAdd="removeItemAdd"
        :style="{ borderBottom: 'none', marginBottom: 0 }"
      />
      <div class="section_goods">
        <div class="info">상품반송지 주소</div>
        <div class="num">{{ returnAddress }}</div>
      </div>
      <div class="section_input">
        <div class="radio_box">
          <label
            ><input type="checkbox" name="" v-model="isOk" /> 교환하려는 상품이
            맞습니다.</label
          >
        </div>
        <div class="radio_sel">
          <h4>교환사유 선택</h4>
          <ul>
            <li v-for="(reason, index) in returnReason" :key="index">
              <label
                ><input
                  type="radio"
                  name=""
                  v-model="refundReason"
                  :value="reason.codecd"
                />{{ reason.value }} (<font
                  :color="
                    parseInt(reason.codecd) <= refundStart
                      ? '#e48600'
                      : '#1e4e9e'
                  "
                  >{{ returnDesc(reason.codecd) }}</font
                >)</label
              >
            </li>
          </ul>
        </div>
        <div class="text_box">
          <textarea
            name=""
            placeholder="구체적인 사유를 적어주세요.
옵션을 잘못 선택하신 경우 교환하기 원하는 
상품의 옵션을 적어주세요.
교환은 동일 상품만 가능합니다.(100자 미만)"
            v-model="reasonDetail"
            maxlength="100"
          ></textarea>
        </div>
        <!-- <div class="text_box">
          <textarea
            name=""
            placeholder="옵션을 잘못 선택하신 경우
교환하기 원하는 상품의 옵션을 적어주세요.
교환은 동일 상품만 가능합니다.(100자 미만)"
          ></textarea>
        </div> -->
        <MyReturnShppingCost
          :isChange="true"
          :isCost="parseInt(refundReason) <= 502"
          @selectType="selectType"
        />
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="orderRefund"
          >교환 요청하기</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderItem from "@/components/mypage/order/OrderItem";
import MyReturnShppingCost from "@/components/mypage/MyReturnShppingCost";
export default {
  props: {
    orderSeq: {
      type: String,
      default: "",
    },
    itemSeq: {
      type: String,
      default: "",
    },
    optionSeq: {
      type: String,
      default: "",
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // orderSeq: this.$route.params.order_seq,
      // itemSeq: this.$route.params.item_seq,
      // optionSeq: this.$route.params.option_seq,
      refundReason: null,
      isOk: false,
      refundStart: 502,
      reasonDetail: null,
      refundItems: [],
      returnMethod: "shop",
      returnCost: 0,
      isRetrunCostPreOrder: false,
      refundShipType: "D",
      isReturnCheck: false,
    };
  },
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getOrderReturn();
    window.addEventListener("message", this.orderReslut);
  },
  computed: {
    ...mapState("order", ["orderData", "result", "msg"]),
    returnReason() {
      return this.orderData.return_reason.filter(
        (reason) => parseInt(reason.codecd) > 501
      );
    },
    returnAddress() {
      let address = "";
      Object.keys(this.orderData.loop).forEach((key) => {
        address = `(${this.orderData.loop[key].return_zipcode}) ${this.orderData.loop[key].return_address}`;
      });
      return address;
    },
    isReturnOk() {
      let returnCnt = 0;
      Object.keys(this.orderData.loop).forEach((key) => {
        this.orderData.loop[key].export_item.forEach((item, index) => {
          if (
            parseInt(this.itemSeq) === parseInt(item.item_seq) &&
            parseInt(this.optionSeq) === parseInt(item.item_option_seq)
          ) {
            returnCnt += parseInt(item.rt_ea);
          }
        });
      });
      console.log("교환가능개수", returnCnt);
      return returnCnt <= 0 ? false : true;
    },
  },
  methods: {
    selectType(type, cost) {
      this.isReturnCheck = true;
      if (parseInt(type) < 3) {
        if (parseInt(type) === 1) {
          this.isRetrunCostPreOrder = true;
          this.refundShipType = "P"; //선결제
        } else {
          this.isRetrunCostPreOrder = false;
          this.refundShipType = "D"; //박스에 동봉
        }
        this.returnMethod = "shop";
      } else {
        this.returnMethod = "user";
        this.isRetrunCostPreOrder = false;
        this.refundShipType = "D"; //박스에 동봉
      }
      this.returnCost = cost;
    },
    closeModal(pageInfo = null) {
      window.removeEventListener("message", this.orderReslut);
      this.$emit("closeModal", "return", pageInfo);
    },
    async getOrderReturn() {
      await this.$store.dispatch("order/getOrderReturn", {
        order_seq: this.orderSeq,
      });
      if (!this.isReturnOk) {
        this.$toast.default("교환할 수 있는 상품 수량이 없습니다.");
        this.closeModal();
        return false;
      }
      if (!this.result) {
        this.$toast.default(this.msg);
        this.closeModal();
        return false;
      }
    },
    orderRefund() {
      if (this.refundItems.length <= 0) {
        this.$toast.default("교환하려는 상품을 선택해주세요.");
        return false;
      }
      if (!this.isOk) {
        this.$toast.default("교환하려는 상품이 맞습니다. 를 체크 해주세요.");
        return false;
      }
      if (!this.refundReason) {
        this.$toast.default("교환 사유를 선택해주세요.");
        return false;
      }
      if (!this.reasonDetail) {
        this.$toast.default("구제적인 교환사유를 입력해주세요.");
        return false;
      }
      if (parseInt(this.refundReason) <= 502 && !this.isReturnCheck) {
        this.$toast.default("배송방법을 선택해주세요.");
        return false;
      }
      let firstTurn = 0;
      let refundData = new FormData();
      refundData.append("order_seq", this.orderData.orders.order_seq);
      Object.keys(this.orderData.loop).forEach((key) => {
        this.refundItems.forEach((refund) => {
          this.orderData.loop[key].export_item.forEach((item) => {
            if (
              parseInt(this.itemSeq) === parseInt(item.item_seq) &&
              parseInt(this.optionSeq) === parseInt(item.item_option_seq)
            ) {
              if (firstTurn === 0) {
                //교환인 경우 무조건 택배비 발생..
                refundData.append("pay_shiping_cost[]", item.swap_shiping_cost);
                refundData.append(
                  "chk_shipping_seq",
                  this.orderData.loop[key].shipping_provider.provider_seq
                );
                firstTurn++;
              }
              if (parseInt(refund.itemSuboption) === 0) {
                if (item.opt_type.toLowerCase() !== "sub") {
                  refundData.append(
                    "chk_shipping_seq",
                    this.orderData.loop[key].shipping_provider.provider_seq
                  );
                  refundData.append(
                    "chk_shipping_group_address[]",
                    `(반송주소) ${this.orderData.loop[key].shipping_provider.deli_zipcode} ${this.orderData.loop[key].shipping_provider.deli_address1} ${this.orderData.loop[key].shipping_provider.deli_address2}`
                  );
                  refundData.append("chk_seq[]", "1");
                  refundData.append("chk_item_seq[]", item.item_seq);
                  refundData.append("chk_option_seq[]", item.item_option_seq);
                  if (item.opt_type === "sub") {
                    refundData.append("chk_suboption_seq[]", item.option_seq);
                  } else {
                    refundData.append("chk_suboption_seq[]", "");
                  }
                  refundData.append("chk_export_code[]", item.export_code);
                  refundData.append(
                    "chk_individual_return[]",
                    item.individual_return
                  );
                  refundData.append("input_chk_ea[]", refund.ea);
                  refundData.append("chk_ea[]", refund.ea);
                }
              } else {
                if (
                  parseInt(this.itemSeq) === parseInt(item.item_seq) &&
                  parseInt(this.optionSeq) === parseInt(item.item_option_seq) &&
                  parseInt(refund.itemSuboption) === parseInt(item.option_seq)
                ) {
                  refundData.append(
                    "chk_shipping_seq",
                    this.orderData.loop[key].shipping_provider.provider_seq
                  );
                  refundData.append(
                    "chk_shipping_group_address[]",
                    `(반송주소) ${this.orderData.loop[key].shipping_provider.deli_zipcode} ${this.orderData.loop[key].shipping_provider.deli_address1} ${this.orderData.loop[key].shipping_provider.deli_address2}`
                  );
                  refundData.append("chk_seq[]", "1");
                  refundData.append("chk_item_seq[]", item.item_seq);
                  refundData.append("chk_option_seq[]", item.item_option_seq);
                  if (item.opt_type === "sub") {
                    refundData.append("chk_suboption_seq[]", item.option_seq);
                  } else {
                    refundData.append("chk_suboption_seq[]", "");
                  }
                  refundData.append("chk_export_code[]", item.export_code);
                  refundData.append(
                    "chk_individual_return[]",
                    item.individual_return
                  );
                  refundData.append("input_chk_ea[]", refund.ea);
                  refundData.append("chk_ea[]", refund.ea);
                }
              }
            }
          });
        });
      });
      refundData.append("reason", this.refundReason);

      const desc = this.orderData.return_reason.filter(
        (reason) => parseInt(reason.codecd) === parseInt(this.refundReason)
      );
      refundData.append("reason_desc", desc[0].value);
      refundData.append("reason_detail", this.reasonDetail);
      refundData.append(
        "cellphone[]",
        this.orderData.orders.order_cellphone[0]
      );
      refundData.append(
        "cellphone[]",
        this.orderData.orders.order_cellphone[1]
      );
      refundData.append(
        "cellphone[]",
        this.orderData.orders.order_cellphone[2]
      );
      refundData.append("phone[]", "");
      refundData.append("phone[]", "");
      refundData.append("phone[]", "");
      refundData.append("return_method", this.returnMethod);
      refundData.append(
        "return_recipient_new_zipcode",
        this.orderData.orders.recipient_new_zipcode
      );
      refundData.append(
        "return_recipient_address_type",
        this.orderData.orders.recipient_address_type
      );
      refundData.append(
        "return_recipient_address",
        this.orderData.orders.recipient_address
      );
      refundData.append(
        "return_recipient_address_street",
        this.orderData.orders.recipient_address_street
      );
      refundData.append(
        "return_recipient_address_detail",
        this.orderData.orders.recipient_address_detail
      );
      refundData.append("refund_ship_type", this.refundShipType);
      refundData.append("mode", "exchange");
      refundData.append("return_cost", this.returnCost);
      // for (var pair of refundData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      // return false;
      this.$confirm(
        "교환 신청을 위해 상품 수령을 확인하세요. 상품을 수령하셨습니까?"
      )
        .then(async () => {
          await this.$store.dispatch("order/setOrderReturn", refundData);
          if (this.msg) {
            this.$toast.default(this.msg);
          }
          if (this.result) {
            this.init();
            this.$refs.orderItem.init();
            //this.getOrderReturn();
            if (this.refundShipType === "P") {
              this.$emit("paymentShppingCost");
              return false;
            }
            this.closeModal(this.pageInfo);
            return false;
          }
          // for (var pair of refundData.entries()) {
          //   console.log(pair[0] + ", " + pair[1]);
          // }
        })
        .catch(() => {
          return false;
        });
    },
    async orderReslut(event) {
      if (event.data.result) {
        document.querySelector("#pay_frame").style.display = "none";
        this.$toast.default(event.data.msg);
        this.closeModal(this.pageInfo);
      }
      this.$store.dispatch("common/setLoading", false);
    },
    init() {
      this.refundItems = [];
      this.isOk = false;
      this.reasonDetail = null;
      this.refundReason = null;
    },
    returnDesc(code) {
      return parseInt(code) <= this.refundStart
        ? "교환배송비 : 구매자 부담"
        : "교환배송비 : 판매자 부담";
    },
    removeItemAdd(isCheck, data, type = null) {
      if (isCheck) {
        if (type === "all") {
          this.refundItems = data;
        } else {
          this.refundItems.push(data);
        }
      } else {
        const idx = this.refundItems.findIndex(
          (item) => item.itemSuboption === data.itemSuboption
        );
        this.refundItems.splice(idx, 1);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("message", this.orderReslut);
  },
  components: {
    OrderItem,
    MyReturnShppingCost,
  },
};
</script>

<style lang="scss" scoped>
.text_box {
  textarea::placeholder {
    font-size: 14px !important;
  }
}
</style>
