<template>
  <div>
    <div class="rn_my_wrap">
      <div class="rn_my_tit_2">
        내 결제 내역<router-link to="/mypage/my_info"
          ><img src="@/assets/images/sub/back_ico.png" alt=""
        /></router-link>
      </div>
      <!---->
      <!-- <div class="rn_my_empty_30"></div> -->
      <!---->
      <div class="rn_my_slide">
        <!-- <div class="left_slide"><a href="#">&lt;</a></div> -->
        <div class="slide_cate">
          <div class="slide_box">
            <div class="box">
              <div class="on">
                <router-link to="" @click.native="resetPage">상품</router-link>
              </div>
              <!-- <div><a href="#">책/음반</a></div>
            <div class="on"><a href="#">뉴스</a></div>
            <div><a href="#">영화</a></div>
            <div><a href="#">음악</a></div>
            <div><a href="#">학원</a></div>
            <div><a href="#">병원</a></div>
            <div><a href="#">헬스</a></div>
            <div><a href="#">뷰티</a></div>
            <div><a href="#">보험</a></div>
            <div><a href="#">금융</a></div>
            <div><a href="#">증권</a></div>
            <div><a href="#">부동산</a></div>
            <div><a href="#">여행</a></div>
            <div><a href="#">맛집</a></div>
            <div><a href="#">공연/전시</a></div>
            <div><a href="#">게임</a></div>
            <div><a href="#">스포츠</a></div>
            <div><a href="#">웹툰</a></div>
            <div><a href="#">컨설팅</a></div>
            <div><a href="#">기타</a></div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div> -->
            </div>
          </div>
        </div>
        <!-- <div class="right_slide"><a href="#">&gt;</a></div> -->
      </div>
      <!---->
      <div class="rn_my_empty_20"></div>
      <!---->
      <div class="rn_my_box_26">
        <div class="top">
          <ul>
            <li>주문 취소는 '상품 준비' 전에만 가능합니다.</li>
            <li>직배송의 경우 배송조회가 불가능합니다.</li>
            <li>
              상품 구매자가 구매확정을 하지 않아도 발송일 기준 10일 후에
              자동으로 구매확정이 됩니다.
            </li>
            <li>
              발송일 기준 5영업일 이내 또는 판매자가 고시한 기일 안에 상품을
              전달받지 못한 분은 [신고하기] 버튼을 눌러주시기 바랍니다.
            </li>
            <li>교환/반품요청은 상품 수령 후 7일 이내에 가능합니다.</li>
            <li>구매확정 이후에는 교환/반품이 불가능합니다.</li>
            <li>
              현금영수증 신청을 원하시면 [영수증 조회] 버튼을 눌러주세요. 발급,
              정정, 취소는 판매자에게 문의하세요.
            </li>
          </ul>
        </div>
        <!--발송(직배송) 준비중-->
        <!--배송중-->
        <!--배송완료-->
        <!--구매확정완료-->
        <!--리뷰평가완료-->
        <!--반품거부-->
        <!--반품중-->
        <!--환불완료-->
        <!--교환중-->
        <!--교환거부-->
        <!--교환완료-->

        <div v-for="(item, index) in listArray" :key="index">
          <PaymentItem
            :items="addExport(item.items, item.exports)"
            :steps="steps"
            :orgIdx="index"
            :info="{
              depositDate: item.deposit_date,
              providerPhone: item.provider_phone,
            }"
            @openModal="openModal"
            @viewCardReceipt="viewCardReceipt"
          />
        </div>
      </div>
      <!---->
      <div class="rn_more">
        <router-link
          to=""
          @click.native="getPaymentList()"
          v-if="parseInt(totalPage) >= parseInt(page)"
          >결제 내역 더보기<img
            src="@/assets/images/sub/my_down_ico.png"
            alt=""
        /></router-link>
        <p v-if="listArray.length <= 0">결제 내역이 없습니다.</p>
      </div>
      <div class="rn_info">
        주식회사 리뷰앤메이크머니는 통신판매 당사자가 아닌 통신판매중개자입니다.
        상품, 상품정보, 거래에 관한 의무와 책임은 통신판매 당사자인 판매자에게
        있습니다.
      </div>
    </div>

    <MyOrderDetail
      v-if="isDetail"
      class="modals"
      :orderSeq="orderSeq"
      @closeModal="closeModal"
    />
    <MyOrderConfirm
      v-if="isConfirm"
      class="modals"
      :orderSeq="orderSeq"
      :itemSeq="itemSeq"
      :optionSeq="optionSeq"
      :pageInfo="{
        position,
        page,
      }"
      @resetPaymentList="resetPaymentList"
      @closeModal="closeModal"
    />
    <MyOrderRefund
      v-if="isRefund"
      class="modals"
      :pageInfo="{
        position,
        page,
      }"
      :orderSeq="orderSeq"
      :itemSeq="itemSeq"
      :optionSeq="optionSeq"
      @closeModal="closeModal"
      @paymentShppingCost="paymentShppingCost"
    />
    <MyOrderChange
      v-if="isReturn"
      class="modals"
      :pageInfo="{
        position,
        page,
      }"
      :orderSeq="orderSeq"
      :itemSeq="itemSeq"
      :optionSeq="optionSeq"
      @closeModal="closeModal"
      @paymentShppingCost="paymentShppingCost"
    />
    <MyOrderCancel
      v-if="isCancel"
      class="modals"
      :pageInfo="{
        position,
        page,
      }"
      :orderSeq="orderSeq"
      @closeModal="closeModal"
    />
    <MyOrderDeclaration
      v-if="isDeclaration"
      :orderSeq="orderSeq"
      :itemSeq="itemSeq"
      :optionSeq="optionSeq"
      :pageInfo="{
        position,
        page,
      }"
      class="modals"
      @closeModal="closeModal"
    />
    <MyOrderReceipt
      v-if="isReceipt"
      :orderSeq="orderSeq"
      :pageInfo="{
        position,
        page,
      }"
      class="modals"
      @closeModal="closeModal"
    />
    <form
      id="pg_form"
      method="post"
      action="/lg_ramm/ramm_return_auth"
      target="tar_opener"
    >
      <input type="hidden" name="order_seq" value="" />
      <input type="hidden" name="mobilenew" value="" />
    </form>
    <iframe
      id="pay_frame"
      name="tar_opener"
      frameborder="0"
      width="100%"
      height="100%"
      scrolling="auto"
      class="pay_ments"
      style="display: none"
    ></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PaymentItem from "@/components/mypage/order/PaymentItem";
import MyOrderConfirm from "@/components/mypage/MyOrderConfirm";
import MyOrderDetail from "@/components/mypage/MyOrderDetail";
import MyOrderRefund from "@/components/mypage/MyOrderRefund";
import MyOrderChange from "@/components/mypage/MyOrderChange";
import MyOrderCancel from "@/components/mypage/MyOrderCancel";
import MyOrderDeclaration from "@/components/mypage/MyOrderDeclaration";
import MyOrderReceipt from "@/components/mypage/MyOrderReceipt";
export default {
  data() {
    return {
      orderNum: this.$route.query.order_num || null,
      isConfirm: false,
      isDetail: false,
      isRefund: false,
      isReturn: false,
      isCancel: false,
      isDeclaration: false,
      isReceipt: false,
      orderSeq: "",
      itemSeq: "",
      optionSeq: "",
      isFixed: false,
      position: 0,
      page: 1,
      nowPage: 0,
      range: 0,
      steps: [
        { step: 15, name: "주문 접수" },
        { step: 25, name: "결제 확인" },
        { step: 35, name: "상품 준비" },
        { step: 45, name: "출고 준비" },
        { step: 50, name: "부분 출고완료" },
        { step: 55, name: "출고 완료" },
        { step: 60, name: "부분 배송중" },
        { step: 65, name: "배송중" },
        { step: 70, name: "배송 완료(부분 배송)" }, //배송완료
        { step: 75, name: "배송 완료" }, //배송완료
        { step: 85, name: "결제 취소" },
        { step: 95, name: "주문 무효" },
        { step: 99, name: "결제 실패" },
      ],
    };
  },
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });

    this.getPaymentList();
  },
  computed: {
    ...mapState("order", [
      "listArray",
      "result",
      "msg",
      "totalPage",
      "returnData",
    ]),
    ...mapState("mypage", {
      shippingReceipt: "shippingReceipt",
      shppingResult: "result",
      shppingMsg: "msg",
    }),
  },
  methods: {
    async viewCardReceipt(return_code) {
      await this.$store.dispatch("mypage/shippingReceipt", { return_code });
      const tno = this.shippingReceipt.pg_transaction_number;
      const authdata = this.shippingReceipt.authdata;
      const shopid = this.shippingReceipt.shopid;
      if (this.shppingResult) {
        showReceiptByTID(shopid, tno, authdata);
      } else {
        this.$toast.default("등록된 결제 정보가 없습니다.");
      }
    },
    paymentShppingCost() {
      this.$store.dispatch("common/setLoading", true);
      const pgdata = this.returnData;
      const form = document.forms.pg_form;
      form.elements.order_seq.value = pgdata.order_seq;
      form.elements.mobilenew.value = "y";
      form.submit();
      document.querySelector("#pay_frame").style.display = "block";
    },
    addExport(item, exports) {
      let export_code = "";
      if (exports) {
        item.forEach((in_item) => {
          let index = -1;
          exports.map((ex) => {
            in_item.options.forEach((op) => {
              if (op.exports) {
                export_code = ex.export_code;
                if (export_code === op.exports.export_code) {
                  let code = ex.delivery_company_code;
                  op.delivery = `${ex.delivery_company_array[code].url}${ex.delivery_number}`;
                }
              }
            });
            if (ex.item) {
              index = ex.item.findIndex(
                (ex_i) => parseInt(ex_i.item_seq) === parseInt(in_item.item_seq)
              );
            }
            if (index !== -1) {
              in_item.export_item_seq = ex.item[index].item_seq;
              in_item.exports = ex;
            }
          });
        });
      }
      return item;
    },
    resetPaymentList() {
      // console.log("getPaymentList");

      this.page = this.nowPage;
      this.getPaymentList();
    },
    resetPage() {
      this.orderNum = null;
      this.page = 0;
      this.getPaymentList();
      this.page++;
    },
    async getPaymentList(position = null) {
      await this.$store.dispatch("order/getPaymentList", {
        page: this.page,
        reset: position ? true : false,
        range: this.range,
        order_seq: this.orderNum,
      });

      //증가 되기전에 페이지 번호를 담아둠
      this.nowPage = this.page;
      this.page++;
      if (position) {
        window.scrollTo(0, Number(position));
      }
    },
    openModal({ pageName, orderSeq, itemSeq, optionSeq }) {
      this.orderSeq = orderSeq;
      this.itemSeq = itemSeq;
      this.optionSeq = optionSeq;
      document.querySelector("body").style.overflow = "hidden";
      this.position = window.scrollY;
      switch (pageName) {
        case "confirm":
          this.isConfirm = true;
          break;
        case "detail":
          this.isDetail = true;
          break;
        case "refund":
          this.isRefund = true;
          break;
        case "return":
          this.isReturn = true;
          break;
        case "cancel":
          this.isCancel = true;
          break;
        case "declaration":
          this.isDeclaration = true;
          break;
        case "receipt":
          this.isReceipt = true;
          break;
      }
    },
    closeModal(pageName, pageInfo = null) {
      this.orderSeq = "";
      this.itemSeq = "";
      this.orderSeq = "";
      document.querySelector("body").style.overflow = "";
      if (pageInfo) {
        this.page = 1;
        this.range = parseInt(pageInfo.page) - 1;
        this.getPaymentList(pageInfo.position);
      }
      switch (pageName) {
        case "confirm":
          this.isConfirm = false;
          break;
        case "detail":
          this.isDetail = false;
          break;
        case "refund":
          this.isRefund = false;
          break;
        case "return":
          this.isReturn = false;
          break;
        case "cancel":
          this.isCancel = false;
          break;
        case "declaration":
          this.isDeclaration = false;
          break;
        case "receipt":
          this.isReceipt = false;
          break;
      }
    },
  },
  components: {
    MyOrderConfirm,
    PaymentItem,
    MyOrderDetail,
    MyOrderRefund,
    MyOrderChange,
    MyOrderCancel,
    MyOrderDeclaration,
    MyOrderReceipt,
  },
  beforeDestroy() {
    document.querySelector("html").style.overflow = "";
  },
};
</script>

<style lang="scss" scoped>
.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #fff;
  height: calc(100% - 90.33px);
  overflow: scroll;
}
.rn_more {
  p {
    border-top: 1px solid #e4e8eb;
    padding: 40px 0 0 0;
  }
}
.pay_ments {
  margin: 0px auto 50px auto;
  position: fixed;
  z-index: 9999999999;
  top: 0;
}
</style>
