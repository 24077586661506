<template>
  <div>
    <!-- 상품 리스트 반복 시작 -->
    <template v-for="(item, midx) in orderList">
      <!-- 본상품 -->
      <div
        class="section_goods"
        v-if="
          parseInt(item.ea ? item.ea : 0) >
          parseInt(countRefund(item.opt_refund))
        "
        :key="`opt_${midx}${item.item_seq}${item.item_option_seq}`"
        :id="`opt_${midx}${item.item_seq}${item.item_option_seq}`"
        :style="orgIdx <= 0 ? 'border-top: 1px solid #000' : ''"
      >
        <div class="info">
          <div
            class="left"
            v-if="
              parseInt(item.step) >= 70 &&
              item.exports &&
              item.exports.buy_confirm !== 'none' &&
              !item.ramm_sale
            "
          >
            구매확정 완료
          </div>
          <div
            class="left"
            v-else-if="
              parseInt(item.step) >= 70 &&
              item.exports &&
              item.exports.buy_confirm !== 'none' &&
              item.ramm_sale &&
              item.ramm_sale.score
            "
          >
            리뷰평가 완료
          </div>
          <div class="left" v-else>
            {{ stepName(item.step) }}
          </div>
          <div
            class="right"
            v-if="parseInt(item.step) < 90 && parseInt(item.step) > 20"
          >
            {{ day(info.depositDate) }} 결제
          </div>
        </div>
        <div class="num"><b>주문번호</b>&nbsp;&nbsp;{{ item.order_seq }}</div>
        <div class="no_top_padding">
          <b>상품번호</b>&nbsp;&nbsp;{{ item.goods_seq }}{{ item.item_seq
          }}{{ item.item_option_seq }}
        </div>
        <div class="goods">
          <div class="sel">
            <span>본상품</span>
            <span
              class="right"
              v-if="parseInt(item.step) > 40 && parseInt(item.step) < 85"
              >배송 개수 {{ item.exp_ea }}개</span
            >
            <span class="right" v-else
              >주문 개수 {{ item.ea ? item.ea : 0 }}개</span
            >
            <span
              class="right order"
              v-if="parseInt(item.step) > 40 && parseInt(item.step) < 85"
              >주문 개수 {{ item.ea ? item.ea : 0 }}개</span
            >
          </div>
          <div class="img" @click="goGoods(item.category, item.goods_seq)">
            <img :src="item.image" @error="replaceImg" alt="" />
          </div>
          <div class="txt">
            <h3 @click="goGoods(item.category, item.goods_seq)">
              {{ item.goods_name }}
            </h3>
            <!-- <h4>
              {{
                won(
                  parseInt(item.sale_price) * parseInt(item.ea ? item.ea : 0)
                )
              }}원
              <span
                v-if="
                  parseInt(item.sale_price) * parseInt(item.ea ? item.ea : 0) <
                  parseInt(item.price) * parseInt(item.ea ? item.ea : 0)
                "
                >{{
                  won(parseInt(item.price) * parseInt(item.ea ? item.ea : 0))
                }}</span
              >
            </h4> -->
            <h4>
              {{ won(parseInt(item.sale_price)) }}원
              <span v-if="parseInt(item.sale_price) < parseInt(item.price)">{{
                won(parseInt(item.price))
              }}</span>
            </h4>
            <p class="option" v-if="item.title1">
              {{ item.title1 }} : {{ item.option1 }}
            </p>
            <p class="option" v-if="item.title2">
              {{ item.title2 }} : {{ item.option2 }}
            </p>
            <p class="option" v-if="item.title3">
              {{ item.title3 }} : {{ item.option3 }}
            </p>
            <p class="option" v-if="item.title4">
              {{ item.title4 }} : {{ item.option4 }}
            </p>
            <p class="option" v-if="item.title5">
              {{ item.title5 }} : {{ item.option5 }}
            </p>
          </div>
        </div>
        <p>
          <span class="left" v-if="parseInt(item.provider_seq) > 1"
            >{{ item.provider_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</span
          >
          <span class="left" v-else>{{ item.provider_name }}</span>
          <span class="right"
            ><router-link
              to=""
              @click.native="
                openModal({
                  pageName: 'detail',
                  orderSeq: item.order_seq,
                })
              "
              >주문상세 &gt;</router-link
            ></span
          >
        </p>
        <!--- 버 튼 -->
        <div class="sect_btn">
          <div
            class="btn_step_3"
            v-if="parseInt(item.step) >= 85 || parseInt(item.step) <= 15"
          >
            <div class="black">
              <a :href="`tel:${info.providerPhone}`">판매자 전화 문의 ☎</a>
            </div>

            <div class="gray right">
              <router-link
                :to="`/market_goods_detail/${item.category}/${item.goods_seq}/qna`"
                >Q&A 문의</router-link
              >
            </div>
          </div>
          <div
            class="btn_share_3"
            v-if="
              parseInt(item.step) >= 70 &&
              parseInt(item.step) <= 75 &&
              item.exports &&
              item.exports.buy_confirm !== 'none' &&
              item.ramm_sale &&
              !item.ramm_sale.score
            "
          >
            <div class="black left">
              <router-link
                :to="`/review/goods_main/${item.ramm_sale.category_code}?goods_seq=0&option1=${item.ramm_sale.option_category1}&option2=${item.ramm_sale.option_category2}&option3=${item.ramm_sale.option_category3}&seq=${item.ramm_sale.seq}`"
                >리뷰 평가하기</router-link
              >
            </div>
            <div class="yellow left">
              <router-link
                :to="`/market_goods_detail/${item.category}/${item.goods_seq}`"
                >재구매</router-link
              >
            </div>
            <div class="gray left">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'receipt',
                    orderSeq: item.order_seq,
                  })
                "
                >영수증조회</router-link
              >
            </div>
          </div>
          <div
            class="btn_step_3"
            v-if="
              parseInt(item.step) >= 70 &&
              parseInt(item.step) <= 75 &&
              item.exports &&
              item.exports.buy_confirm !== 'none' &&
              item.ramm_sale &&
              item.ramm_sale.score
            "
          >
            <div class="yellow">
              <router-link
                :to="`/market_goods_detail/${item.category}/${item.goods_seq}`"
                >재구매</router-link
              >
            </div>
            <div class="gray right">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'receipt',
                    orderSeq: item.order_seq,
                  })
                "
                >영수증조회</router-link
              >
            </div>
          </div>
          <div
            class="btn_step_3"
            v-if="
              parseInt(item.step) >= 70 &&
              parseInt(item.step) <= 75 &&
              item.exports &&
              item.exports.buy_confirm !== 'none' &&
              !item.ramm_sale
            "
          >
            <div class="yellow">
              <router-link
                :to="`/market_goods_detail/${item.category}/${item.goods_seq}`"
                >재구매</router-link
              >
            </div>
            <div class="gray right">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'receipt',
                    orderSeq: item.order_seq,
                  })
                "
                >영수증조회</router-link
              >
            </div>
          </div>
          <div
            class="btn_step_5"
            v-if="
              parseInt(item.step) >= 35 &&
              parseInt(item.step) <= 75 &&
              (!item.exports || item.exports.buy_confirm == 'none')
            "
          >
            <div class="yellow_dack">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'confirm',
                    orderSeq: item.order_seq,
                    itemSeq: item.item_seq,
                    optionSeq: item.item_option_seq,
                  })
                "
                >구매<br />확정</router-link
              >
            </div>
            <div class="yellow">
              <a :href="item.delivery" target="_blank">배송<br />조회</a>
            </div>
            <div class="black">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'refund',
                    orderSeq: item.order_seq,
                    itemSeq: item.item_seq,
                    optionSeq: item.item_option_seq,
                  })
                "
                >반품<br />요청</router-link
              >
            </div>
            <div class="black">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'return',
                    orderSeq: item.order_seq,
                    itemSeq: item.item_seq,
                    optionSeq: item.item_option_seq,
                  })
                "
                >교환<br />요청</router-link
              >
            </div>
            <div class="gray">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'receipt',
                    orderSeq: item.order_seq,
                  })
                "
                >영수증<br />조회</router-link
              >
            </div>
            <div class="white" v-if="!item.declaration">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'declaration',
                    orderSeq: item.order_seq,
                    itemSeq: item.item_seq,
                    optionSeq: item.item_option_seq,
                  })
                "
                >신고<br />하기</router-link
              >
            </div>
            <div class="yellow_dack" v-else>
              <router-link to=""
                >신고<br />{{
                  item.declaration.result_yn === "Y" ? "완료" : "접수"
                }}</router-link
              >
            </div>
          </div>
          <div
            class="btn_step_3"
            v-if="parseInt(item.step) >= 25 && parseInt(item.step) < 35"
          >
            <div class="black">
              <a :href="`tel:${info.providerPhone}`">판매자 전화 문의 ☎</a>
            </div>
            <div class="yellow">
              <router-link
                to=""
                @click.native="
                  openModal({
                    pageName: 'cancel',
                    orderSeq: item.order_seq,
                  })
                "
                >주문취소</router-link
              >
            </div>
            <div class="gray">
              <router-link
                :to="`/market_goods_detail/${item.category}/${item.goods_seq}/qna`"
                >Q&A 문의</router-link
              >
            </div>
          </div>
        </div>
        <!-- 버튼 끝  -->
        <ul class="refund_info" v-if="parseInt(item.step) === 85">
          <li>
            환불은 환불이 승인된 날로부터 <b>즉시</b> 또는 가맹점-카드사 간에
            전표 매입이 이루어진 후에는 <b>영업일 기준 3~5일</b> 후에
            이루어집니다.
          </li>
          <li>
            주문취소가 완료되면 신용카드 결제는 승인 취소, 계좌이체나
            무통장입급은 입금계좌 또는 결제 시 지정한 고객님의 환불계좌로
            환불됩니다.
          </li>
          <li>
            승인취소/환불처리 내역은 3영업일 후 해당 금융기관에서 확인하실 수
            있습니다.
          </li>
          <li>
            구매확정 후 반품/환불이 이루어지는 경우 리뷰평가와 관련해 지급된
            포인트는 환수됩니다.
          </li>
        </ul>
      </div>
      <!-- 본상품 끝 -->

      <!-- 본상품 반품/ 교환 -->
      <template v-if="item.opt_refund.length > 0">
        <div
          class="section_goods"
          v-for="(refund, idx) in item.opt_refund"
          :key="`refund_opt_${idx}${refund.return_item_seq}`"
          :id="`refund_opt_${idx}${refund.return_item_seq}`"
        >
          <div class="info">
            <div class="left">{{ returnState(refund) }}</div>
            <div class="right">{{ day(info.depositDate) }} 결제</div>
          </div>
          <div class="num"><b>주문번호</b>&nbsp;&nbsp;{{ item.order_seq }}</div>
          <div class="no_top_padding">
            <b>상품번호</b>&nbsp;&nbsp;{{ item.goods_seq }}{{ item.item_seq
            }}{{ item.item_option_seq }}
          </div>
          <div class="goods">
            <div class="sel">
              <span>본상품</span>
              <span class="right"
                >{{ refund.return_type === "return" ? "반품" : "교환" }} 개수
                {{ refund.ea }}개</span
              >
              <span class="right order"
                >주문 개수 {{ item.ea ? item.ea : 0 }}개</span
              >
            </div>
            <div class="img" @click="goGoods(item.category, item.goods_seq)">
              <img :src="item.image" @error="replaceImg" alt="" />
            </div>
            <div class="txt">
              <h3 @click="goGoods(item.category, item.goods_seq)">
                {{ item.goods_name }}
              </h3>
              <!-- <h4>
                {{
                  won(
                    parseInt(item.sale_price) * parseInt(item.ea ? item.ea : 0)
                  )
                }}원
                <span
                  v-if="
                    parseInt(item.sale_price) *
                      parseInt(item.ea ? item.ea : 0) <
                    parseInt(item.price) * parseInt(item.ea ? item.ea : 0)
                  "
                  >{{
                    won(parseInt(item.price) * parseInt(item.ea ? item.ea : 0))
                  }}</span
                >
              </h4> -->
              <h4>
                {{ won(parseInt(item.sale_price)) }}원
                <span v-if="parseInt(item.sale_price) < parseInt(item.price)">{{
                  won(parseInt(item.price))
                }}</span>
              </h4>
              <p class="option" v-if="item.title1">
                {{ item.title1 }} : {{ item.option1 }}
              </p>
              <p class="option" v-if="item.title2">
                {{ item.title2 }} : {{ item.option2 }}
              </p>
              <p class="option" v-if="item.title3">
                {{ item.title3 }} : {{ item.option3 }}
              </p>
              <p class="option" v-if="item.title4">
                {{ item.title4 }} : {{ item.option4 }}
              </p>
              <p class="option" v-if="item.title5">
                {{ item.title5 }} : {{ item.option5 }}
              </p>
            </div>
          </div>
          <p>
            <span class="left" v-if="parseInt(item.provider_seq) > 1"
              >{{ item.provider_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</span
            >
            <span class="left" v-else>{{ item.provider_name }}</span>
            <span class="right"
              ><router-link
                to=""
                @click.native="
                  openModal({ pageName: 'detail', orderSeq: item.order_seq })
                "
                >주문상세 &gt;</router-link
              ></span
            >
          </p>
          <!-- 버 튼 -->
          <div class="sect_btn">
            <div class="btn_step_3">
              <div class="black">
                <a :href="`tel:${info.providerPhone}`">판매자 전화 문의 ☎</a>
              </div>
              <div class="yellow">
                <router-link to="/cs/qna">고객센터 문의</router-link>
              </div>
              <div class="gray">
                <router-link
                  :to="`/market_goods_detail/${item.category}/${item.goods_seq}/qna`"
                  >Q&A 문의</router-link
                >
              </div>
              <div class="gray">
                <router-link
                  to=""
                  @click.native="viewCardReceipt(refund.return_code)"
                  >배송비<br />영수증</router-link
                >
              </div>
            </div>
          </div>
          <!-- 버튼 끝 -->
          <ul class="refund_info" v-if="refund.return_type === 'return'">
            <li>
              환불은 환불이 승인된 날로부터 <b>즉시</b> 또는 가맹점-카드사 간에
              전표 매입이 이루어진 후에는 <b>영업일 기준 3~5일</b> 후에
              이루어집니다.
            </li>
            <li>
              주문취소가 완료되면 신용카드 결제는 승인 취소, 계좌이체나
              무통장입급은 입금계좌 또는 결제 시 지정한 고객님의 환불계좌로
              환불됩니다.
            </li>
            <li>
              승인취소/환불처리 내역은 3영업일 후 해당 금융기관에서 확인하실 수
              있습니다.
            </li>
            <li>
              구매확정 후 반품/환불이 이루어지는 경우 리뷰평가와 관련해 지급된
              포인트는 환수됩니다.
            </li>
          </ul>
        </div>
      </template>
      <!-- 본상품 반품/교환 끝-->

      <!-- 추가 상품 -->
      <template v-for="(sub, index) in getSuboption(item.suboptions)">
        <div
          class="section_goods add_goods"
          v-if="
            parseInt(sub.ea) > parseInt(countRefund(item.sub_refund[index])) ||
            item.sub_refund[index].length <= 0
          "
          :id="`sub_${index}${sub.item_seq}${sub.item_option_seq}`"
          :key="`sub_${index}${sub.item_seq}${sub.item_option_seq}`"
        >
          <div class="info">
            <div class="left">{{ stepName(sub.step) }}</div>
            <div
              class="right"
              v-if="parseInt(sub.step) < 90 && parseInt(sub.step) > 20"
            >
              {{ day(info.depositDate) }} 결제
            </div>
          </div>
          <div class="num"><b>주문번호</b>&nbsp;&nbsp;{{ item.order_seq }}</div>
          <div class="no_top_padding">
            <b>상품번호</b>&nbsp;&nbsp;{{ item.goods_seq }}{{ item.item_seq
            }}{{ item.item_option_seq }}
          </div>
          <div class="add_goods_box">
            <div class="sel">
              <span>추가상품</span>
              <span
                class="right"
                v-if="parseInt(sub.step) > 40 && parseInt(sub.step) < 85"
                >배송 개수 {{ sub.exp_ea ? sub.exp_ea : 0 }}개</span
              >
              <span class="right" v-else
                >주문 개수 {{ sub.ea ? sub.ea : 0 }}개</span
              >
              <span
                class="right order"
                v-if="parseInt(sub.step) > 40 && parseInt(sub.step) < 85"
                >주문 개수 {{ sub.ea ? sub.ea : 0 }}개</span
              >
            </div>
            <div class="txt">
              <h3>{{ sub.title }}({{ sub.suboption }})</h3>
              <h4>
                <!-- {{ won(parseInt(sub.price) * parseInt(sub.ea ? sub.ea : 0)) }}원 -->
                {{ won(parseInt(sub.price)) }}원
              </h4>
            </div>
          </div>
          <p>
            <span class="left" v-if="parseInt(item.provider_seq) > 1"
              >{{ item.provider_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</span
            >
            <span class="left" v-else>{{ item.provider_name }}</span>
            <span class="right">
              <span class="right"
                ><router-link
                  to=""
                  @click.native="
                    openModal({
                      pageName: 'detail',
                      orderSeq: item.order_seq,
                    })
                  "
                  >주문상세 &gt;</router-link
                ></span
              ></span
            >
          </p>
          <!-- 버 튼  -->
          <div class="sect_btn">
            <div
              class="btn_step_3"
              v-if="parseInt(item.step) <= 15 || parseInt(item.step) >= 85"
            >
              <div class="black">
                <a :href="`tel:${info.providerPhone}`">판매자 전화 문의 ☎</a>
              </div>

              <div class="gray right">
                <router-link
                  :to="`/market_goods_detail/${item.category}/${item.goods_seq}/qna`"
                  >Q&A 문의</router-link
                >
              </div>
            </div>
            <div
              class="btn_share_3"
              v-if="parseInt(item.step) >= 70 && parseInt(item.step) <= 75"
            >
              <!-- <div class="black left"><a href="#">리뷰 평가하기</a></div> -->
              <!-- <div class="yellow left"><a href="#">재구매</a></div> -->
              <!-- <div class="gray right"><a href="#">영수증조회</a></div> -->
            </div>
            <div
              class="btn_step_5"
              v-if="
                parseInt(item.step) >= 35 &&
                parseInt(item.step) <= 75 &&
                (!item.exports || item.exports.buy_confirm == 'none')
              "
            >
              <div class="yellow_dack">
                <router-link
                  to=""
                  @click.native="
                    openModal({
                      pageName: 'confirm',
                      orderSeq: item.order_seq,
                      itemSeq: item.item_seq,
                      optionSeq: item.item_option_seq,
                    })
                  "
                  >구매<br />확정</router-link
                >
              </div>
              <div class="yellow">
                <a :href="item.delivery" target="_blank">배송<br />조회</a>
              </div>
              <div class="black">
                <router-link
                  to=""
                  @click.native="
                    openModal({
                      pageName: 'refund',
                      orderSeq: item.order_seq,
                      itemSeq: item.item_seq,
                      optionSeq: item.item_option_seq,
                    })
                  "
                  >반품<br />요청</router-link
                >
              </div>
              <div class="black">
                <router-link
                  to=""
                  @click.native="
                    openModal({
                      pageName: 'return',
                      orderSeq: item.order_seq,
                      itemSeq: item.item_seq,
                      optionSeq: item.item_option_seq,
                    })
                  "
                  >교환<br />요청</router-link
                >
              </div>
              <div class="gray">
                <router-link
                  to=""
                  @click.native="
                    openModal({
                      pageName: 'receipt',
                      orderSeq: item.order_seq,
                    })
                  "
                  >영수증<br />조회</router-link
                >
              </div>
              <div class="white" v-if="!sub.result_yn">
                <router-link
                  to=""
                  @click.native="
                    openModal({
                      pageName: 'declaration',
                      orderSeq: item.order_seq,
                      itemSeq: item.item_seq,
                      optionSeq: item.item_option_seq,
                    })
                  "
                  >신고<br />하기</router-link
                >
              </div>
              <div class="yellow_dack" v-else>
                <router-link to=""
                  >신고<br />{{
                    sub.result_yn === "Y" ? "완료" : "접수"
                  }}</router-link
                >
              </div>
            </div>
            <div
              class="btn_step_3"
              v-if="parseInt(item.step) >= 25 && parseInt(item.step) < 35"
            >
              <div class="black">
                <a :href="`tel:${info.providerPhone}`">판매자 전화 문의 ☎</a>
              </div>
              <div class="yellow">
                <router-link
                  to=""
                  @click.native="
                    openModal({
                      pageName: 'cancel',
                      orderSeq: item.order_seq,
                    })
                  "
                  >주문취소</router-link
                >
              </div>
              <div class="gray">
                <router-link
                  :to="`/market_goods_detail/${item.category}/${item.goods_seq}/qna`"
                  >Q&A 문의</router-link
                >
              </div>
            </div>
          </div>
          <!-- 버튼 끝 -->
          <ul class="refund_info" v-if="parseInt(item.step) === 85">
            <li>
              환불은 환불이 승인된 날로부터 <b>즉시</b> 또는 가맹점-카드사 간에
              전표 매입이 이루어진 후에는 <b>영업일 기준 3~5일</b> 후에
              이루어집니다.
            </li>
            <li>
              주문취소가 완료되면 신용카드 결제는 승인 취소, 계좌이체나
              무통장입급은 입금계좌 또는 결제 시 지정한 고객님의 환불계좌로
              환불됩니다.
            </li>
            <li>
              승인취소/환불처리 내역은 3영업일 후 해당 금융기관에서 확인하실 수
              있습니다.
            </li>
            <li>
              구매확정 후 반품/환불이 이루어지는 경우 리뷰평가와 관련해 지급된
              포인트는 환수됩니다.
            </li>
          </ul>
        </div>

        <!-- 추가상품 반품/ 교환 -->
        <template v-if="item.sub_refund.length > 0">
          <div
            class="section_goods add_goods"
            v-for="refund in item.sub_refund[index]"
            :key="`refund_sub_${index}${refund.return_item_seq}`"
            :id="`refund_sub_${index}${refund.return_item_seq}`"
          >
            <div class="info">
              <div class="left">{{ returnState(refund) }}</div>
              <div class="right">{{ day(info.depositDate) }} 결제</div>
            </div>
            <div class="num">
              <b>주문번호</b>&nbsp;&nbsp;{{ item.order_seq }}
            </div>
            <div class="no_top_padding">
              <b>상품번호</b>&nbsp;&nbsp;{{ item.goods_seq }}{{ item.item_seq
              }}{{ item.item_option_seq }}
            </div>
            <div class="add_goods_box">
              <div class="sel">
                <span>추가 상품</span>
                <span class="right"
                  >{{ refund.return_type === "return" ? "반품" : "교환" }} 개수
                  {{ refund.ea }}개</span
                >
                <span class="right order"
                  >주문 개수 {{ sub.ea ? sub.ea : 0 }}개</span
                >
              </div>
              <div class="txt">
                <h3>{{ sub.title }}({{ sub.suboption }})</h3>
                <!-- <h4>
                  {{
                    won(parseInt(sub.price) * parseInt(sub.ea ? sub.ea : 0))
                  }}원
                </h4> -->
                <h4>{{ won(parseInt(sub.price)) }}원</h4>
              </div>
            </div>
            <p>
              <span class="left" v-if="parseInt(item.provider_seq) > 1"
                >{{ item.provider_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램 가게</span
              >
              <span class="left" v-else>{{ item.provider_name }}</span>
              <span class="right">
                <span class="right"
                  ><router-link
                    to=""
                    @click.native="
                      openModal({
                        pageName: 'detail',
                        orderSeq: item.order_seq,
                      })
                    "
                    >주문상세 &gt;</router-link
                  ></span
                ></span
              >
            </p>
            <!-- 버 튼 -->
            <div class="sect_btn">
              <div class="btn_step_3">
                <div class="black">
                  <a :href="`tel:${info.providerPhone}`">판매자 전화 문의 ☎</a>
                </div>
                <div class="yellow">
                  <router-link to="/cs/qna">고객센터 문의</router-link>
                </div>
                <div class="gray">
                  <router-link
                    :to="`/market_goods_detail/${item.category}/${item.goods_seq}/qna`"
                    >Q&A 문의</router-link
                  >
                </div>
                <div class="gray">
                  <router-link
                    to=""
                    @click.native="viewCardReceipt(refund.return_code)"
                  >
                    배송비<br />영수증</router-link
                  >
                </div>
              </div>
            </div>
            <!--버튼 끝  -->
            <ul class="refund_info" v-if="refund.return_type === 'return'">
              <li>
                환불은 환불이 승인된 날로부터 <b>즉시</b> 또는 가맹점-카드사
                간에 전표 매입이 이루어진 후에는 <b>영업일 기준 3~5일</b> 후에
                이루어집니다.
              </li>
              <li>
                주문취소가 완료되면 신용카드 결제는 승인 취소, 계좌이체나
                무통장입급은 입금계좌 또는 결제 시 지정한 고객님의 환불계좌로
                환불됩니다.
              </li>
              <li>
                승인취소/환불처리 내역은 3영업일 후 해당 금융기관에서 확인하실
                수 있습니다.
              </li>
              <li>
                구매확정 후 반품/환불이 이루어지는 경우 리뷰평가와 관련해 지급된
                포인트는 환수됩니다.
              </li>
            </ul>
          </div>
        </template>
        <!-- 추가 상품 반품/교환 끝 -->
      </template>
      <!-- 추가 상품 반복 끝-->
    </template>
    <!-- 상품 리스트 반복 끝 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    steps: {
      type: Array,
      default: () => [],
      required: true,
    },
    info: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    orgIdx: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    orderList() {
      this.items.map((item) => {
        item.options.map((option) => {
          option.goods_name = item.goods_name;
          option.provider_name = item.provider_name;
          option.provider_seq = item.provider_seq;
          option.image = item.image;
          option.exports = item.exports;
          this.itemList.push(option);
        });
      });
      return this.itemList;
    },
  },
  methods: {
    viewCardReceipt(return_code) {
      this.$emit("viewCardReceipt", return_code);
    },
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    goGoods(category, goods_seq) {
      this.$router.push(`/market_goods_detail/${category}/${goods_seq}`);
    },
    won(str) {
      return this.$won(str);
    },
    day(str) {
      return this.$moment(str).format("YYYY-MM-DD");
    },
    countRefund(refund) {
      return refund.reduce((l, r) => parseInt(l) + parseInt(r.ea), 0);
    },
    returnState(refund) {
      let msg = "";
      switch (refund.status) {
        case "request":
          msg = refund.return_type === "return" ? "반품 신청" : "교환 신청";
          break;
        case "ing":
          msg = refund.return_type === "return" ? "반품중" : "교환중";
          break;
        case "complete":
          msg = refund.return_type === "return" ? "반품 완료" : "교환 완료";
          break;
      }
      return msg;
    },
    getSuboption(suboptions) {
      let list = [];
      if (suboptions) {
        Object.keys(suboptions).forEach((key) => {
          list.push(suboptions[key]);
        });
      }

      return list;
    },
    stepName(step) {
      let name = "";
      this.steps.map((st) => {
        if (parseInt(st.step) === parseInt(step)) {
          name = st.name;
        }
      });
      return name;
    },
    openModal(data) {
      this.$emit("openModal", data);
    },
    reOrder() {},
  },
};
</script>

<style lang="scss" scoped>
.section_goods {
  .no_top_padding {
    padding: 0 0 10px 0;
  }
  .sel {
    .right {
      float: right;
      &.order {
        color: #6d6d6d;
        font-size: 13px;
        font-weight: 400;
        margin-right: 25px;
      }
    }
  }
  .txt {
    h4 {
      padding: 0 0 5px 0;
    }
  }
  .option {
    padding: 0px 0px;
    line-height: 18px;
  }
  &.add_goods {
    .add_goods_box {
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      padding: 10px 0;
    }
    .txt {
      h4 {
        padding: 0;
      }
    }
  }
  .sect_btn {
    .btn_step_5 {
      div {
        line-height: 15px;
        padding: 5px 0;
      }
      .yellow_dack {
        background-color: $info-txt;
      }
    }
    .btn_share_3 {
      .right {
        float: right;
      }
    }
  }
}
</style>
