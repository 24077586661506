<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      주문 상세정보<router-link to="" @click.native="closeModal"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_24">
      <div class="sect_cont">
        <div class="box">
          <h4>주문정보</h4>
          <div>
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>주문일자</td>
                  <!-- <td
                    v-if="
                      orderData.orders.deposit_yn === 'n' &&
                      !orderData.cancel_log
                    "
                  >
                    입금대기
                  </td>
                  <td
                    v-if="
                      orderData.cancel_log && orderData.cancel_log.length > 0
                    "
                  >
                    {{ orderData.cancel_log[0].title }}
                  </td>
                  <td
                    v-if="
                      orderData.orders.deposit_yn === 'y' &&
                      !orderData.cancel_log
                    "
                  > -->
                  <!-- <td>{{ day(orderData.orders.deposit_date) }} 결제</td> -->
                  <td>{{ day(orderData.orders.regist_date) }}</td>
                </tr>
                <tr>
                  <td>주문번호</td>
                  <td>{{ orderData.orders.order_seq }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box" style="margin-top: auto">
          <h4>주문하신 상품</h4>
          <div>
            <OrderDetailGoods
              v-for="(item, index) in itemList"
              :key="index"
              :item="item"
            />
          </div>
        </div>
        <div class="box">
          <h4>결제정보</h4>
          <div>
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>결제일</td>
                  <td v-if="orderData.orders.deposit_yn === 'n'">입금대기</td>
                  <td v-else>{{ daytime(orderData.orders.deposit_date) }}</td>
                </tr>
                <tr>
                  <td>결제방법</td>
                  <td v-if="orderData.orders.payment === 'bank'">
                    {{ orderData.orders.mpayment }}&nbsp;(입금자명:{{
                      orderData.orders.depositor
                    }})
                  </td>
                  <td v-if="orderData.orders.payment === 'escrow_virtual'">
                    {{ orderData.orders.mpayment }}&nbsp;(입금자명:{{
                      orderData.orders.depositor
                    }})
                  </td>
                  <td v-if="orderData.orders.payment === 'virtual'">
                    {{ orderData.orders.mpayment }}&nbsp;(입금자명:{{
                      orderData.orders.depositor
                    }})
                  </td>
                  <td
                    v-if="
                      orderData.orders.payment === 'card' &&
                      orderData.orders.payment_cd !== 'MONEY'
                    "
                  >
                    {{ orderData.orders.mpayment }}&nbsp;
                    <template
                      v-if="
                        orderData.order_pg_log &&
                        orderData.order_pg_log.card_name
                      "
                    >
                      <span> ({{ orderData.order_pg_log.card_name }}/ </span>
                      <span v-if="parseInt(orderData.orders.card_quota) > 0">
                        {{ orderData.orders.card_quota }}개월할부)
                      </span>
                      <span v-else> 일시불) </span>
                    </template>
                    <template v-else>
                      <span>(일시불) </span>
                    </template>
                  </td>
                </tr>
                <tr
                  v-if="
                    (orderData.orders.bank_account ||
                      orderData.orders.virtual_account) &&
                    orderData.account_number
                  "
                >
                  <td>입금계좌</td>
                  <td>
                    {{ orderData.account_number.bank_name }}
                    {{ orderData.account_number.account_number }}<br />({{
                      orderData.account_number.account_owner
                    }})
                  </td>
                </tr>
                <tr
                  v-if="
                    orderData.orders.deposit_yn === 'n' &&
                    orderData.cfg_order.autocancel_txt
                  "
                >
                  <td>입금기간</td>
                  <td>{{ orderData.cfg_order.autocancel_txt }}</td>
                </tr>
                <tr>
                  <td>결제금액</td>
                  <td>
                    상품금액 {{ won(parseInt(orderData.items_tot.price)) }}원
                    <br />- 할인금액
                    {{
                      won(
                        parseInt(orderData.items_tot.event_sale) +
                          parseInt(orderData.items_tot.multi_sale) +
                          parseInt(orderData.items_tot.member_sale) +
                          parseInt(orderData.items_tot.mobile_sale) +
                          parseInt(orderData.items_tot.fblike_sale) +
                          parseInt(orderData.items_tot.coupon_sale) +
                          parseInt(orderData.items_tot.promotion_code_sale) +
                          parseInt(orderData.items_tot.referer_sale) +
                          parseInt(orderData.shipping_tot.coupon_sale) +
                          parseInt(orderData.shipping_tot.code_sale) +
                          parseInt(orderData.orders.enuri)
                      )
                    }}원 <br />- 램 포인트
                    {{ won(parseInt(orderData.orders.emoney)) }}원 <br />+
                    배송비
                    {{
                      won(orderData.shipping_tot.total_shipping_cost)
                    }}원<br />= 총 {{ won(orderData.orders.settleprice) }}원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="box" v-if="orderData.orders">
          <h4>배송지 정보</h4>
          <div>
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>받는 분</td>
                  <td>{{ orderData.orders.recipient_user_name }}</td>
                </tr>
                <tr v-if="orderData.orders.merge_recipient_phone">
                  <td>전화번호</td>
                  <td>{{ orderData.orders.merge_recipient_phone }}</td>
                </tr>
                <tr>
                  <td>휴대폰번호</td>
                  <td v-if="orderData.orders.recipient_cellphone">
                    {{ orderData.orders.recipient_cellphone[0] }}-{{
                      orderData.orders.recipient_cellphone[1]
                    }}-{{ orderData.orders.recipient_cellphone[2] }}
                  </td>
                </tr>
                <tr>
                  <td>주소</td>
                  <td>
                    ({{ orderData.orders.merge_recipient_zipcode }})
                    {{
                      orderData.orders.recipient_address_street !== "null"
                        ? orderData.orders.recipient_address_street
                        : orderData.orders.recipient_address
                    }}
                    {{ orderData.orders.recipient_address_detail }}
                  </td>
                </tr>
                <tr>
                  <td>배송메모</td>
                  <td>{{ orderData.orders.memo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="box">
          <h4>배송 현황</h4>
          <div>
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>주문접수일</td>
                  <td>2020-09-09 12:00:00</td>
                </tr>
                <tr>
                  <td>상품발송일</td>
                  <td>2020-09-10</td>
                </tr>
                <tr>
                  <td>배송완료일</td>
                  <td>2020-09-12</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table summary="">
              <colgroup>
                <col width="80px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>반품접수일</td>
                  <td>2020-09-09 12:00:00</td>
                </tr>
                <tr>
                  <td>교환접수일</td>
                  <td>2020-09-10 12:00:00</td>
                </tr>
                <tr>
                  <td>진행상태</td>
                  <td>
                    <ul>
                      <li>제품 반송중(2020-09-13 21:31:55)</li>
                      <li>반품 완료(2020-09-13 21:31:55)</li>
                      <li>반품 거부(2020-09-13 21:31:55)</li>
                      <li>환불중(2020-09-13 21:31:55)</li>
                      <li>환불 완료(2020-09-13 21:31:55)</li>
                      <li>제품 교환중(2020-09-13 21:31:55)</li>
                      <li>제품 교환 완료(2020-09-13 21:31:55)</li>
                      <li>교환 거부(2020-09-13 21:31:55)</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </div>
      <!-- <div class="sect_btn">
        <div class="btn_step_5">
          <div class="yellow"><a href="#">구매확정</a></div>
          <div class="black"><a href="#">반품요청</a></div>
          <div class="black"><a href="#">교환요청</a></div>
          <div class="gray"><a href="#">영수증조회</a></div>
          <div class="white"><a href="#">신고하기</a></div>
        </div>
        <div class="btn_step_3">
          <div class="black"><a href="#">판매자 전화 문의 ☎</a></div>
          <div class="gray"><a href="#">Q&A 문의</a></div>
          <div class="gray"><a href="#">고객센터 문의</a></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderDetailGoods from "@/components/mypage/order/OrderDetailGoods";
export default {
  props: {
    orderSeq: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // orderSeq: this.$route.params.order_seq || this.order_seq,
      itemListArray: [],
      orderSeqParam: this.$route.params.order_seq || this.orderSeq,
    };
  },
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getOrderView();
  },
  methods: {
    async getOrderView() {
      await this.$store.dispatch("order/getOrderView", {
        order_seq: this.orderSeqParam,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
        this.$router.push("/");
      }
    },
    closeModal() {
      if (this.$route.params.order_seq) {
        this.$router.push("/mypage/payment_list");
        return false;
      }
      this.$emit("closeModal", "detail");
    },
    // goBackPage() {
    //   //this.$router.push(`/mypage/order_cancel/${this.orderSeq}`);
    //     this.$router.go(-1);
    // },
    day(str) {
      return this.$moment(str).format("YYYY-MM-DD");
    },
    daytime(str) {
      return this.$moment(str).format("YYYY-MM-DD H:mm:ss");
    },
    won(str) {
      return this.$won(str);
    },
  },
  computed: {
    ...mapState("order", ["orderData", "result", "msg"]),
    itemList() {
      if (this.orderData.shipping_group_items) {
        Object.keys(this.orderData.shipping_group_items).forEach((key) => {
          this.orderData.shipping_group_items[key].items.forEach((item) => {
            item.options.forEach((option) => {
              let data = {};
              data.checked = parseInt(option.step) === 15;
              data.provider_seq = item.provider_seq;
              data.provider_name = item.provider_name;
              data.image = item.image;
              data.ea = option.ea;
              data.goods_name = item.goods_name;
              data.goods_seq = item.goods_seq;
              data.item_option_seq = option.item_option_seq;
              data.item_seq = option.item_seq;
              data.title1 = option.title1;
              data.title2 = option.title2;
              data.title3 = option.title3;
              data.title4 = option.title4;
              data.title5 = option.title5;
              data.option1 = option.option1;
              data.option2 = option.option2;
              data.option3 = option.option3;
              data.option4 = option.option4;
              data.option5 = option.option5;
              data.out_price = option.out_price;
              data.sale_price = option.sale_price;
              let price = 0;
              let arrays = [];
              if (option.suboptions) {
                Object.keys(option.suboptions).forEach((key) => {
                  price +=
                    parseInt(option.suboptions[key].price) *
                    parseInt(
                      option.suboptions[key].ea ? option.suboptions[key].ea : 0
                    );
                  arrays.push(option.suboptions[key]);
                });
              }
              data.suboptions = arrays;
              data.addPrice = price;
              this.itemListArray.push(data);
            });
          });
        });
      }
      return this.itemListArray;
    },
  },
  components: {
    OrderDetailGoods,
  },
};
</script>
<style lang="scss" scoped>
.rn_my_box_24 .sect_cont .box div {
  border-bottom: none;
}
</style>
